import { districtFromPostcode } from 'helper/data/shops';
import { logAnalytics, push, splitPostcode } from './helper';

export const trackPostcode = (postcode) => {
  logAnalytics('location', {
    ...splitPostcode(postcode),
    type: 'address',
  });
};

export const trackNumberOfOrders = (orders) => {
  push({
    orders: orders.toString()
  });
};
