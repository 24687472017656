import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { openModal } from '@redq/reuse-modal';
import {
  NoStoresWrapper,
  NoStoresTitle,
  RiderImageWrapper,
  ContentWrapper,
  NoStoresLabel,
  EmailFieldWrapper,
  ButtonWrapper,
} from './NoStoresAvailable.style';
import Rider from 'image/Rider.png';
import Input from 'components/Input/Input';

import { SUBSCRIBE_TO_MISSING_COVERAGE } from 'graphql/mutation/me';
import { validateEmail } from 'helper/user';
import { parseError } from 'helper/error';
import Log from 'helper/sentry';
import { logAnalytics, splitPostcode } from 'helper/gtm/helper';

type NoStoresAvailableProps = {
  postcode: string;
};

const NoStoresAvailable: React.FC<NoStoresAvailableProps> = ({
  postcode,
}) => {
  const [subscribe, { loading }] = useMutation(SUBSCRIBE_TO_MISSING_COVERAGE);
  const [email, setEmail] = useState('');
  const [hasSentEmail, setHasSentEmail] = useState(false);
  const [error, setError] = useState(null);

  const _logAnalytics = (step: 'select' | 'enter_email' | 'submit' | 'cancel') => {
    logAnalytics('location_notify_me', {
      ...splitPostcode(postcode),
      step
    });
  };

  useEffect(() => {
    _logAnalytics('select');
    return () => _logAnalytics('cancel');
  }, []);

  // API

  const onClickButton = async () => {
    if (!validateEmail(email)) {
      setError('Invalid email');
      return;
    }
    
    _logAnalytics('submit');

    const args = {
      email: encodeURI(email).replace('@', '%40'),
      postcode: postcode
    };

    try {
      Log.debug('Triggering SUBSCRIBE_TO_MISSING_COVERAGE...', 'out-of-area', args);
      const response = await subscribe({
        context: { headers: { Accept: '' }},
        variables: args,
      });

      Log.debug('SUBSCRIBE_TO_MISSING_COVERAGE responded', 'out-of-area', response);
      setHasSentEmail(true);
    } catch (error) {
      Log.warning('SUBSCRIBE_TO_MISSING_COVERAGE caught error', 'out-of-area', error);
      if (error?.networkError?.statusCode === 409) {
        setHasSentEmail(true);
      } else {
        setError(error);
      }
    }
  }

  // ACTIONS

  const onChange = (value: string) => {
    setEmail(value);
    if (error) {
      setError(null);
    }
  }

  // RENDER

  return (
    <NoStoresWrapper>
      <NoStoresTitle>We're not there yet!</NoStoresTitle>
      <RiderImageWrapper>
        <img src={Rider} alt="Devo Bike Rider"/>
      </RiderImageWrapper>
      { hasSentEmail ?
          <NoStoresLabel>Thanks! We'll let you know as soon as we deliver to this area.</NoStoresLabel>
        :
          <ContentWrapper>
            <NoStoresLabel>We're sorry but we don't currently deliver to this area. We'll notify you when Devo arrives.</NoStoresLabel>
            <EmailFieldWrapper>
              <Input
                type="text"
                intlPlaceholderId="noStoresAvailableEmailField"
                value={email}
                onUpdate={onChange}
                onFocus={() => _logAnalytics('enter_email')}
              />
            </EmailFieldWrapper>

            { error &&
              <NoStoresLabel style={{ color: 'red', marginBottom: 20 }}>
                {parseError(error)}
              </NoStoresLabel>
            }

            <ButtonWrapper
              title="Notify Me"
              iconPosition="left"
              onClick={onClickButton}
              disabled={email.length === 0}
              isLoading={loading}
            />
          </ContentWrapper>
      }
    </NoStoresWrapper>
  );
};

const showNotifyMe = (postcode: string) => {
  Log.debug('Showing out of area dialog', 'out-of-area', { postcode: postcode });
  openModal({
    show: true,
    closeOnClickOutside: true,
    component: NoStoresAvailable,
    componentProps: { postcode: postcode },
    config: {
      width: 700,
      height: 'auto',
    }
  });
};

export default showNotifyMe;
