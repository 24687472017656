import React, { ChangeEvent } from 'react'
import CurrentLocationArrow from 'image/home/current-location-arrow.svg';
import { CurrentLocationButton, SearchBox, SearchInputWrapper } from './LocationSearchBox.style'

type InputProps = {
  value: string;
  bordered: boolean;
  onSubmit: (e: any) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onCurrentLocationClick: (e: any) => void;
};

const InputSearch: React.FC<InputProps> = ({
  value,
  bordered,
  onSubmit,
  onChange,
  onCurrentLocationClick,
}) => {
  return (
    <>
      <SearchInputWrapper className={`input-wrapper ${bordered ? 'bordered' : ''}`}>
        <form onSubmit={onSubmit}>
          <SearchBox>
            <input
              placeholder={'Enter your postcode...'}
              value={value}
              onChange={onChange}
            />

            <CurrentLocationButton type="button" onClick={onCurrentLocationClick}>
              <img src={CurrentLocationArrow} />
            </CurrentLocationButton>
          </SearchBox>
        </form>
      </SearchInputWrapper>
    </>
  );
};
export default InputSearch;
