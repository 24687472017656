import gql from 'graphql-tag';

export const UPDATE_ME = gql`
  mutation updateCurrentUser($input: any) {
    updateMe(input: $input) @rest(type: "UserModel", method: "PATCH", path: "/user/", endpoint: "as") {
      uuid
      firstName
      lastName
      email
    }
  }
`;

export const SUBSCRIBE_TO_MISSING_COVERAGE = gql`
  mutation subscribeToMissingCoverage($email: string, $postcode: string) {
    subscribe(email: $email, postcode: $postcode, input: {})
    @rest(method: "POST", path: "/user/subscribeToMissingCoverage?email={args.email}&postcode={args.postcode}", endpoint: "as") {
      NoResponse
    }
  }
`;
