import styled from 'styled-components';
import Button from 'components/Button/Button';

export const NoStoresWrapper = styled.div`
  background-color: #fff;
`;

export const NoStoresTitle = styled.h4`
  text-align: center;
  padding: 20px;
  border-bottom: 1px #ebebeb solid;
`;

export const RiderImageWrapper = styled.div`
  height: 125px;
  margin: 40px 0;

  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  padding: 25px;
`;

export const NoStoresLabel = styled.p`
  color: #848686;
  padding: 0 20px 50px;
  text-align: center;
`;

export const EmailFieldWrapper = styled.div`
  width: 100%;
  margin: 10px 0 20px;
  input {
    border: 1px solid #dedede !important;
  }
  input:focus {
    border: 1px solid #ee4f4f !important;
  }
`;

export const ButtonWrapper = styled(Button)`
  text-align: center;
  margin: 0 auto;
  width: 100%;
`;
