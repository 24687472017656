import { fetchCurrentLocation } from 'helper/googlemaps'
import React, { useEffect, useState } from 'react'
import InputSearch from './LocationInput'
import SearchWrapper, { SearchBoxWrapper, SearchButton, Error } from './LocationSearchBox.style'
import { validPostcode } from 'helper/data/location';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_SHOPS_FOR_LOCATION } from 'graphql/query/shops.query';
import { formatPostcode } from 'helper/data/shops';
import Log from 'helper/sentry';
import showNotifyMe from 'components/NoStoresAvailable/NoStoresAvailable';
import { logAnalytics } from 'helper/gtm/helper';

type SearchBoxProps = {
  className?: string;
  onSelectPostcode: (postcode: string) => void;
};

const Search: React.FC<SearchBoxProps> = ({
  className,
  onSelectPostcode,
}) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  // SHOPS FETCH
  const [getShopsForLocation] = useLazyQuery(GET_SHOPS_FOR_LOCATION, {
    fetchPolicy: 'no-cache',
    onCompleted: res => {
      if (res?.shops) {
        Log.debug('GET_SHOPS_FOR_LOCATION onCompleted', 'home', { count: res.shops.length });
        if (res?.shops?.length > 0) {
          onSelectPostcode(value);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setLoading(false);
          showNotifyMe(formatPostcode(value));
        }
      } else {
        Log.error('GET_SHOPS_FOR_LOCATION null response', 'home', { postcode: value, res: res });
        setError('Something went wrong - please try again.');
        setLoading(false);
      }
    },
    onError: err => {
      setError('Something went wrong - please try again.');
      setLoading(false);
    }
  });

  const handleSubmit = (e: any) => {
    if (isLoading) return;
    e.preventDefault();

    if (value.length === 0) {
      setError("Please enter your postcode and try again.");
    } else if (validPostcode(value)) {
      Log.debug('Triggering getShopsForLocation...', 'home', null);

      setLoading(true);
      getShopsForLocation({ variables: {
        postcode: formatPostcode(value)
      }});
    } else {
      setError("We couldn't recognise that postcode – check and try again.");
    }
  };

  const handleChange = (e: any) => {
    setValue(e.target.value);
    setError(null);
  };

  const handleCurrentLocation = async (e: any) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    await fetchCurrentLocation((status, location) => {
      logAnalytics('allow_location_access', {
        option: location ? 'granted' : 'denied'
      });
      setLoading(false);
      setValue(location?.postcode);
    });
  };

  return (
    <>
      <SearchWrapper className={`${className ? className : ''} ${error ? 'error' : ''}`}>
        <SearchBoxWrapper>
          <InputSearch
            value={value}
            bordered={error != null}
            onSubmit={handleSubmit}
            onChange={handleChange}
            onCurrentLocationClick={handleCurrentLocation}
          />
          <SearchButton
            title='&#x2192;'
            onClick={handleSubmit}
            className={isLoading ? 'loading' : ''}
            isLoading={isLoading}
          />
        </SearchBoxWrapper>
        { error && <Error>{ error }</Error> }
      </SearchWrapper>
    </>
  );
};

export default Search;
