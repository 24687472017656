import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Button from 'components/Button/Button';

const SearchWrapper = styled.div`
  position: relative;
  width: 700px;
  margin: 40px auto 0;
  box-shadow: 2px 8px 15px rgba(0,0,0,0.1);

  &.div {
    border-radius: 8px;
  }

  &.error {
    box-shadow: none;
  }

  @media (max-width: 767px) {
    width: 95%;
  }
`;

export const SearchBox = styled.div`
  width: 100%;

  input {
    width: calc(100% - 55px);
    font-size: 16px;
    color: #77798C;
    border: 0;
    padding: 0 20px;
    height: 55px;

    &:focus {
      outline: 0;
    }

    &::placeholder {
      font-size: 16px;
      color: #77798C;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &::-webkit-input-placeholder {
      font-size: 16px;
      color: ${themeGet('colors.darkRegular', '#77798C')};
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:-moz-placeholder {
      font-size: 16px;
      color: ${themeGet('colors.darkRegular', '#77798C')};
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &::-moz-placeholder {
      font-size: 16px;
      color: ${themeGet('colors.darkRegular', '#77798C')};
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &:-ms-input-placeholder {
      font-size: 16px;
      color: ${themeGet('colors.darkRegular', '#77798C')};
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const SearchBoxWrapper = styled.div`
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchInputWrapper = styled.div`
  width: 100%;
  transition: all 0.3s;

  form {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 6px 0 0 6px;
  }

  &.bordered {
    border: 3px solid #EE504F !important;
    height: 55px;
    border-radius: 6px 0px 0px 6px;
  }
`;

export const CurrentLocationButton = styled.button`
  width: 55px;
  height: 55px;
  background-color: transparent;
  border: none;
  vertical-align: top;
  outline: none;

  img {
    height: 25px;
    display: inline-block;
    vertical-align: top;
  }

  &:active {
    background-color: #f5f5f5;
  }
`;

export const SearchButton = styled(Button)`
  height: 55px;
  width: 55px;
  background-color: #EE504F;
  border-radius: 0 6px 6px 0;

  &.loading {
    width: 75px;
    padding: 0;
  }

  span {
    font-size: 25px;
    font-weight: 400;
    color: #ffffff;
  }
`;

export const Error = styled.p`
  color: #EE504F;
  display: block;
  width: 100%;
  margin: 10px 0;
  text-align: left;
  font-size: 16px !important;
`;

export default SearchWrapper;
