import styled from 'styled-components';
import YellowBannerDesktop from 'image/home/yellow-banner-desktop.jpg';
import YellowBannerTablet from 'image/home/yellow-banner-tablet.jpg';
import YellowBannerMobile from 'image/home/yellow-banner-mobile.jpg';

export const Title = styled.h1`
`;

export const Subtitle = styled.p`
  font-size: 1.2em;
`;

export const Subheader = styled.h2`
  margin-bottom: 20px;
`;

export const Separator = styled.div`
  height: 1px;
  width: max(50%, 250px);
  margin: 20px auto;
  background-color: #EEEEEE;
`;

export const Button = styled.button`
    display: block;
    padding: 15px 25px;
    background-color: var(--devo-red);
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      background-color: #ba1212;
    }

    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 4px rgba(238,80,79,0.25);
    }
`;

export const Home = styled.div`
  min-height: 100vh;
  background-color: white;

  h1 {
    font-weight: 800;
    font-size: 2.5em;
    margin-bottom: 20px;

    @media (max-width: 1000px) {
      font-size: 2.2em;
    }

    @media (max-width: 1000px) {
      font-size: 1.9em;
    }
  }

  h2 {
    font-weight: 800;
    font-size: 2em;

    @media (max-width: 1000px) {
      font-size: 1.7em;
    }
  }

  h4 {
    font-weight: 800;
    font-size: 1.5em;

    @media (max-width: 1000px) {
      font-size: 1.25em;
    }
  }
`;

export const Header = styled.div`
  height: ${props => props.height || 90}vh;

  background: ${props => props.color || "#f59412"} url(${props => props.image || YellowBannerDesktop}) no-repeat;
  background-size: cover;
  background-position: center;

  text-align: center;
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    background-image: url(${props => props.image || YellowBannerTablet});
  }

  @media (max-width: 640px) {
    background-image: url(${props => props.image || YellowBannerMobile});
  }
`;

export const BannerContent = styled.div`
  align-self: center;
  width: 95%;

  h1 {
    color: #fff;
  }

  p {
    color: #fafafa;
  }

  &.left {
    width: 75%;
    text-align: left;
  }
`;

export const Page = styled.div`
  width: 1000px;
  margin: 0 auto;

  h2.top {
    text-align: center;
    padding: 100px 0 25px;

    @media (max-width: 650px) {
      padding-top: 75px;
    }
    
    @media (max-width: 650px) {
      padding-top: 50px;
    }
  }

  @media (max-width: 1250px) {
    width: 80%;
  }

  @media (max-width: 767px) {
    margin-bottom: 100px;
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > div {
    margin: 25px 25px 25px 0;
    width: calc(33% - 20px);
  }

  & > div:last-child {
    margin-right: 0;
  }

  @media (max-width: 650px) {
    flex-direction: column;

    & > div {
      margin: 0;
      padding: 0px 20px 30px;
      width: 100%;
    }
  }
`;

export const CategoryGrid = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;

  & > * {
    margin: 20px;
    width: calc(33.33% - 40px);
    height: 150px;
  }

  @media (max-width: 650px) {
    margin: 0 -25px 25px;
    
    & > * {
      margin: 10px;
      width: calc(50% - 20px);
      height: 125px;
    }
  }
`;

export const BottomHeader = styled.div`
  height: 500px;

  background: #EE504F url(${props => props.image}) no-repeat;
  background-size: cover;
  background-position: bottom;
`;

export const BottomOverlay = styled.div`
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const BottomBannerContent = styled.div`
  align-self: center;

  h1 {
    color: #fff;
  }

  p {
    color: #fafafa;
  }
`;

const ReviewWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 0 -20px 100px;
`;

const ReviewCard = styled.div`
  flex: 0 0 calc(33.3333% - 20px);
  display: flex;
  background-color: var(--secondary-background-color);
  padding: 0 30px 0 25px;
  border-radius: 20px;
  transition: all 0.25s ease-in;
  max-height: 0px;
  opacity: 0;
  overflow: hidden;
  margin: 0 10px;

  &.showing {
    padding: 25px 30px 30px 25px;
    margin: 10px;
    max-height: 500px;
    opacity: 1;
    overflow: visible;
  }
  
  @media (max-width: 1024px) {
    flex: 0 0 calc(100% - 20px);
  }
  
  @media (max-width: 640px) {
    flex: auto;
  }
`;

const ReviewContent = styled.div`
  
`;

const ReviewIcon = styled.span`
  margin-right: 20px;
  font-size: 30px;
`;

const ReviewTitle = styled.h4`
  margin-bottom: 10px;
`;

const ReviewStars = styled.div`
  position: relative;
  box-sizing: border-box;
  display: block;
  font-size: 20px;
  font-family: Times; // make sure ★ appears correctly
  line-height: 1;
  margin: 10px 0;
  
  &::before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, #fc0 calc(${props => props.rating} / 5 * 100%), #E0E0E0 calc(${props => props.rating} / 5 * 100%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const ReviewText = styled.span`
`;

const ReviewMoreButton = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;

  a {
    text-align: center;
    margin: 0 auto;
    padding: 15px 25px;
    z-index: 100;
    border-radius: 6px;
    background-color: var(--devo-red);
    color: #fff;
    font-family: var(--primary-font);
    font-size: 14px;
    font-weight: 800;
    cursor: pointer;
  }

  @media (max-width: 480px) {
    a {
      width: 100%;
      margin: 0 10px;
      border-radius: 12px;
    }
  }
`;

export const Review = {
  Wrapper: ReviewWrapper,
  Card: ReviewCard,
  Icon: ReviewIcon,
  Content: ReviewContent,
  Title: ReviewTitle,
  Stars: ReviewStars,
  Text: ReviewText,
  MoreButton: ReviewMoreButton,
};

const AreaWrapper = styled.div`
  margin-bottom: 100px;
`;

const AreaGrid = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const AreaLink = styled.a`
  width: calc(25% - 15px);
  display: inline-block;
  margin: 15px 15px 15px 0;
  font-size: 16px;

  a {
    color: var(--primary-label-color);
    
    &:hover {
      color: var(--devo-red);
    }
  }

  @media (max-width: 768px) {
    width: calc(33.3333% - 15px);
  }

  @media (max-width: 480px) {
    width: calc(50% - 15px);
  }
`;

export const Area = {
  Wrapper: AreaWrapper,
  Grid: AreaGrid,
  Link: AreaLink,
};

const CategoryWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;

  a:not(:last-child) {
    margin-right: 15px;
  }
`;  

const CategoryItem = styled.a`
  display: inline-block;
  padding: 10px 15px;
  color: var(--primary-label-color);
  background-color: var(--secondary-background-color);
  font-size: 20px;
  font-weight: 800;
  border-radius: 10px;
  margin-bottom: 15px;

  span {
    margin-right: 8px;
  }

  &:hover {
    background-color: var(--gray-5);
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const Category = {
  Wrapper: CategoryWrapper,
  Item: CategoryItem,
};

export const ShopsBannerContent = styled.div`
  width: 75%;
  text-align: left;
  align-self: center;
  padding-bottom: 60px;

  h1 {
    color: var(--white);
    margin-bottom: 10px;
  }

  p {
    font-size: 20px;
    color: var(--white);
  }

  @media (max-width: 767px) {
    width: 90%;
    padding-bottom: 0;
    text-align: center;
  }
`;

export const ShopsResultsWrapper = styled.div`
  width: 75%;
  margin: -${props => props.offset || 0}px auto 0;
  padding-bottom: 100px;

  h2 {
    margin-top: 50px;
  }

  @media (max-width: 767px) {
    width: 90%;
    margin-top: 75px;
  }
`;